import '@assets/scss/components/not-found/index.scss';

export default function NotFound() {
	return (
		<section className="not-found-wrapper text-center pt-5 pb-5">
			<img
				src="/images/generic-images/not-found/not-found.svg"
				className="not-found-wrapper__image img-fluid"
				alt="Not Found"
			/>
			<h3 className="mt-3 medium-bold-font">No Data Found</h3>
		</section>
	);
}
