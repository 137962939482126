import { config } from "../../env";
import { Delete, get, patch, post } from "../helpers/http-handler";


export const loginUser = (payload) =>
  post(`${config.API_URL}/provider-users/login`, payload);


export const forgotPassword = (payload) =>
post(`${config.API_URL}/provider-users/forgetPassword`, payload);
