import { getValue } from "@utils/lodash";
import React from "react";

function ThumbUpSvgComponent(props) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
        viewBox="0 0 24 24"
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#423f3f"
        }
      >
        <defs>{/* <style>.a{fill:none;}.b{fill:#5f6368;}</style> */}</defs>
        <path class="a" d="M0,0H24V24H0ZM0,0H24V24H0Z" fill={"none"} />
        <path
          class="b"
          d="M9,21h9a1.987,1.987,0,0,0,1.84-1.22l3.02-7.05A1.976,1.976,0,0,0,23,12V10a2.006,2.006,0,0,0-2-2H14.69l.95-4.57.03-.32a1.505,1.505,0,0,0-.44-1.06L14.17,1,7.58,7.59A1.987,1.987,0,0,0,7,9V19A2.006,2.006,0,0,0,9,21ZM9,9l4.34-4.34L12,10h9v2l-3,7H9ZM1,9H5V21H1Z"
        />
      </svg>
    </div>
  );
}

export default ThumbUpSvgComponent;
