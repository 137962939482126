import { getValue } from "@utils/lodash";
import React from "react";

function ThumbDownSvgComponent(props) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
        viewBox="0 0 24 24"
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#423f3f"
        }
      >
        <defs>{/* <style>.a{fill:none;}.b{fill:#5f6368;}</style> */}</defs>
        <path class="a" d="M24,24H0V0H24Zm0,0H0V0H24Z" fill={"none"} />
        <path
          class="b"
          d="M15,1H6A1.987,1.987,0,0,0,4.16,2.22L1.14,9.27A1.976,1.976,0,0,0,1,10v2a2.006,2.006,0,0,0,2,2H9.31l-.95,4.57-.03.32a1.505,1.505,0,0,0,.44,1.06L9.83,21l6.59-6.59A1.987,1.987,0,0,0,17,13V3A2.006,2.006,0,0,0,15,1Zm0,12-4.34,4.34L12,12H3V10L6,3h9Zm8,0H19V1h4Z"
          transform="translate(0 2)"
        />
      </svg>
    </div>
  );
}

export default ThumbDownSvgComponent;
