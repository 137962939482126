import EmailSentPage from "@pages/private/email-sent";
import LoginPage from "@pages/public/login-page";
import PasswordConfirmPage from "@pages/public/password-confirm";
import PasswordResetPage from "@pages/public/password-reset";

export const PublicRoutes = [
  { path: "/login", name: "PasswordReset", component: LoginPage },
  { path: "/email-sent", name: "PasswordReset", component: EmailSentPage },

  {
    path: "/password-reset",
    name: "PasswordReset",
    component: PasswordResetPage,
  },
  {
    path: "/password-confirm",
    name: "PasswordReset",
    component: PasswordConfirmPage,
  },
];
