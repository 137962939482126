import { config } from "../../env";
import { Delete, get, post, put } from "../helpers/http-handler";
// import config from "../config";

export const getDashboardInfo = () =>
  get(`${config.API_URL}/provider-leads/dashboardDetails`);

//provider leads

export const getAllProviderLeads = (queryRequest) =>
  get(`${config.API_URL}/provider-leads?${queryRequest}`);

export const createProviderLead = (payload) =>
  post(`${config.API_URL}/provider-leads`, payload);

export const getSpecificProviderLead = (id,queryRequest) =>
  get(`${config.API_URL}/provider-leads/${id}?${queryRequest}`);

export const updateProviderLead = (id, payload) =>
  put(`${config.API_URL}/provider-leads/${id}`, payload);

export const deleteProviderLead = (id) =>
  Delete(`${config.API_URL}/provider-leads/${id}`);


  //provider leads - comments

  export const getAllProviderLeadComments = (queryRequest) =>
  get(`${config.API_URL}/provider-lead-comments?${queryRequest}`);

export const createProviderLeadComments = (payload) =>
  post(`${config.API_URL}/provider-lead-comments`, payload);

export const getSpecificProviderLeadComments = (id,queryRequest) =>
  get(`${config.API_URL}/provider-lead-comments/${id}?${queryRequest}`);

export const updateProviderLeadComments = (id, payload) =>
  put(`${config.API_URL}/provider-lead-comments/${id}`, payload);

export const deleteProviderLeadComments = (id) =>
  Delete(`${config.API_URL}/provider-lead-comments/${id}`);
