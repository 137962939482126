import LoginLayout from '@components/layout/login-layout.js';
import EmailSent from '@components/email-sent/email-sent.js';
import '@scss/pages/password-reset/index.scss';

export default function EmailSentPage() {
  return (
    <LoginLayout>
      <EmailSent />
    </LoginLayout>
  );
}
