import LoginLayout from "@components/layout/login-layout.js";
import PasswordReset from "@components/password-reset/password-reset.js";
import "@scss/pages/password-reset/index.scss";
import { forgotPassword } from "@services/APIs/auth.service";
import { getValue } from "@utils/lodash";
// import fetchProviderForgotPassword from "../services/providerForgotPassword/fetchProviderForgotPassword";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";

export default function PasswordResetPage(props) {
  const [email, setEmail] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail(value);
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setIsLoading(true);
        let resp = await forgotPassword({ email: email });
        if (resp) {
          toast.success(getValue(resp, "message", "Email sent successfully"));
          navigate("/email-sent");
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };
  return (
    <LoginLayout>
      <PasswordReset
        email={email}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        simpleValidator={simpleValidator}
      />
    </LoginLayout>
  );
}
