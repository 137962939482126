import moment from "moment";

export const formatText = (value) => {
	switch (value) {
		case "interested":
			return "Interested";
		case "not_interested":
			return "Not Interested";
		case "follow_up":
			return "Follow Up";
		case "fresh_lead":
			return "Fresh Lead";
		default:
			return value;
	}
};

export const convertDateTime = (time) => {
	const formattedTime = moment.utc(time).toISOString();
	return formattedTime;
  };