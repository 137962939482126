import { getValue } from "../../utils/lodash";

export function authHeader() {
  let org_x_code = window.location.pathname.split("/");
  let code =  getValue(org_x_code, `[${1}]`, "")
  let access_token = localStorage.getItem("jwt");
  if (!access_token) {
    return {
      "Content-Type": "application/json",
    };
  } else {
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
  }
}
