import DashboardLayout from "@components/layout/dashboard-layout.js";
import DashboardCard from "@components/dashboard/dashboard-page/dashboard-card.js";
import "@scss/pages/dashboard/index.scss";
import { useEffect, useState } from "react";
// import fetchDashboardDetails from '../services/dashboardDetails/fetchDashboardDetails';
import { getValue } from "@utils/lodash";
import { getDashboardInfo } from "@services/APIs/common.service";
import NotFound from "@components/not-found";
export default function Dashboard() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getDashboardInfo();
      if (resp) {
        setIsLoading(false);
        setData(resp);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <>
      <DashboardLayout>
        <div className="dashboard-main-wrapper-header d-flex justify-content-between align-items-center px-3">
          <h3 className="dashboard-main-wrapper-header__title">
            Leads Overview
          </h3>
        </div>
        {isLoading ? (
          "Please wait..."
        ) : getValue(data, "length", 0) > 0 ? (
          <div className="dashboard-home-wrapper px-4">
            <DashboardCard data={data} />
          </div>
        ) : (
          <NotFound />
        )}
      </DashboardLayout>
    </>
  );
}
