import '@scss/components/home-page/login-form.scss';
import InputField from '../../common/InputRuleField'
import { getValue } from '../../utils/lodash';
export default function Login(props) {
  return (
    <div className="password-reset-wrapper">
      <img
        src="/images/home-page/logo.svg"
        className="img-fluid"
        alt=" "
      />
      <h2 className="password-reset-wrapper__title password-reset-wrapper__title--login-pwd">
        Password Reset
      </h2>
      <p className="password-reset-wrapper__text">
        Your new password should be eight characters with one lowercase letter,
        one upper case letter, one number, one special character
      </p>
      <div className="form-group">
        <label htmlFor="email-address">Password</label>
        <InputField
          inputType="PASSWORD"
          id="password"
          name='password'
          value={getValue(props,'request.password')}
          onChange={props.handleChange}
          validator={props.simpleValidator}
          placeholder="Enter Email ID"
          className="password-reset-wrapper__form-element form-control"
          label='password'
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Confirmation Password</label>
        <InputField
          inputType="PASSWORD"
          id="passwordConfirmation"
          placeholder="Enter Password"
          className="form-control password-reset-wrapper__form-element"
          value={getValue(props,'request.passwordConfirmation')}
          name='passwordConfirmation'
          onChange={props.handleChange}
          validator={props.simpleValidator}
          label='Password Confirmation'
        />
      </div>
      <div className="password-reset-wrapper__cta-wrapper">
          <button className="login-wrapper__cta-btn login-wrapper__cta-btn--theme-primary-btn w-100" onClick={props.handleSubmit}>
            {props.isLoading?'Please wait...':'Submit'}
          </button>
      </div>
    </div>
  );
}
