import DashboardSvgComponent from "@assets/svg/dashboard";
import OfferSvgComponent from "@assets/svg/offer";
import PendingSvgComponent from "@assets/svg/pending";
import ThumbDownSvgComponent from "@assets/svg/thumb-down";
import ThumbUpSvgComponent from "@assets/svg/thumb-up";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

export default function DashboardHeader() {
  const [dashboardNavigationArray] = useState([
    {
      linkUrl: "/dashboard",
      imageUrl: "/images/dashboard/navigation/dashboard.svg",
      title: "Dashboard",
      name: "dashboard",
    },
    {
      linkUrl: "/fresh-leads",
      imageUrl: "/images/dashboard/navigation/offer.svg",
      title: " Fresh Leads",
      name: "fresh-leads",
    },
    {
      linkUrl: "/followup-overdue",
      imageUrl: "/images/dashboard/navigation/pending.svg",
      title: "Followup Overdue",
      name: "follow-up",
    },
    {
      linkUrl: "/interested",
      imageUrl: "/images/dashboard/navigation/thumb-up.svg",
      title: "Interested",
      name: "interested",
    },
    {
      linkUrl: "/not-interested",
      imageUrl: "/images/dashboard/navigation/thumb-down.svg",
      title: "Not Interested",
      name: "not-interested",
    },
  ]);

  // const match = useRouteMatch();

  useEffect(() => {
    document.addEventListener("click", function (e) {
      if (e.target && e.target.id == "toogle-left-navigation-id_js") {
        document
          .querySelector(".dashboard-navigation")
          .classList.toggle("dashboard-navigation--collapse");
      }
    });
  });

  const getIcons = (name, color) => {
    switch (name) {
      case "dashboard":
        return <DashboardSvgComponent color={color} size={22} />;
      case "fresh-leads":
        return <OfferSvgComponent color={color} size={22} />;
      case "follow-up":
        return <PendingSvgComponent color={color} size={22} />;
      case "interested":
        return <ThumbUpSvgComponent color={color} size={22} />;
      case "not-interested":
        return <ThumbDownSvgComponent color={color} size={22} />;
      default:
        return null;
    }
  };

  return (
    <div className="dashboard-navigation">
      <ul>
        {dashboardNavigationArray.map((tempObj, index) => {
          return (
            <li
              className="dashboard-navigation__list"
              key={`dashboard${index}`}
            >
              <NavLink
                to={tempObj.linkUrl}
                className={`dashboard-navigation__link-nav ${
                  window.location.pathname === tempObj.linkUrl
                    ? "dashboard-navigation__link-nav--active"
                    : window.location.pathname === "/" && index === 0
                    ? "dashboard-navigation__link-nav--active"
                    : ""
                }`}
              >
                {/* <img
                  src={tempObj.imageUrl}
                  className="dashboard-navigation__image"
                  alt=" "
                /> */}
                {getIcons(
                  getValue(tempObj, `name`, ""),
                  window.location.pathname === tempObj.linkUrl
                    ? "#1539b0"
                    : window.location.pathname === "/" && index === 0
                    ? "#1539b0"
                    : ""
                )}
                <p className="ms-3">{tempObj.title}</p>
              </NavLink>
            </li>
          );
        })}
      </ul>
      <div className="dashboard-navigation__icon d-flex">
        <img
          src="/images/generic-images/left-arrow.svg"
          if="toogle-left-navigation-id_js"
          className="dashboard-navigation__image-icon img-fluid cursor-pointer toogle-left-navigation_js"
        />
      </div>
    </div>
  );
}
