import Aboutus from "@pages/private/about-us.js";
import Dashboard from "@pages/private/dashboard.js";
import FreshLeads from "@pages/private/fresh-leads.js";
import FollowupOverdue from "@pages/private/followup-overdue.js";
import Interested from "@pages/private/interested.js";
import NotInterested from "@pages/private/not-interested.js";
import FreshLeadsInnerpage from "@pages/inside/fresh-leads-inner-page.js";

export const PrivateRoutes = [
  { path: "/", name: "Dashboard", component: Dashboard },
  { path: "/about-us", name: "Aboutus", component: Aboutus },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/fresh-leads", name: "FreshLeads", component: FreshLeads },
  {
    path: "/followup-overdue",
    name: "FollowupOverdue",
    component: FollowupOverdue,
  },
  { path: "/interested", name: "Interested", component: Interested },
  { path: "/not-interested", name: "NotInterested", component: NotInterested },
  //detail screens
  {
    path: "/fresh-leads/:id",
    name: "FreshLeadsInnerPage",
    component: FreshLeadsInnerpage,
  },
  {
    path: "/followup-overdue/:id",
    name: "FollowUpInnerpage",
    component: FreshLeadsInnerpage,
  },
  {
    path: "/interested/:id",
    name: "IntrestedInnerpage",
    component: FreshLeadsInnerpage,
  },
  {
    path: "/not-interested/:id",
    name: "NotIntrestedInnerpage",
    component: FreshLeadsInnerpage,
  },
];
