import React, { useEffect, useState } from "react";
import "@scss/components/fresh-leads/leads-inner-page.scss";
import { getValue, setValue } from "../../utils/lodash";
import Moment from "react-moment";
import { toast } from "react-toastify";
import InputField from "../../common/InputRuleField";
import { Col } from "reactstrap";
import { NavLink, useParams } from "react-router-dom";
import DetailLayout from "@components/layout/layout";
import {
  createProviderLeadComments,
  getSpecificProviderLead,
  updateProviderLead,
} from "@services/APIs/common.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getAllProviderLeadComments } from "@services/APIs/common.service";
import { concatString, getFirstLetter } from "@common/text-helpers";

export default function FreshLeadsInnerpage(props) {
  const params = useParams();
  const [providerLead, setProviderLead] = useState({});
  const [comment, setComment] = useState("");
  const [providerLeadComments, setProviderLeadComments] = useState([]);
  useEffect(() => {
    getData();
    getProviderComments();
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const [loading1, setloading1] = useState(false);
  const [statusList, setStatusList] = useState([
    { value: "interested", label: "Interested" },
    { value: "not_interested", label: "Not Interested" },
    { value: "follow_up", label: "Follow Up" },
    { value: "fresh_lead", label: "Fresh Lead" },
  ]);

  const getProviderComments = async () => {
    try {
      // https://admin.healthtunnel.com/api/provider-lead-comments?sort=createdAt:DESC&filters[provider_lead]=404&populate=provider_user
      let payload = {
        sort: "createdAt:DESC",
        "filters[provider_lead]": getValue(params, `id`, ""),
        populate: "provider_user",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllProviderLeadComments(queryRequest);
      if (resp) {
        console.log(resp);
        setProviderLeadComments(getValue(resp, "results", []));
      }
    } catch (error) {}
  };
  const getData = async () => {
    try {
      setIsLoading(true);
      let where = {
        populate:
          "status,user,provider.logo,provider.sub_category,provider.sub_category.app_image,provider.sub_category.category,provider.sub_category.web_image",
        // fields: "id,city,createdAt,zip_code,status",
      };
      let queryRequest = QueryRequestHelper(where);
      let resp = await getSpecificProviderLead(
        getValue(params, `id`, ""),
        queryRequest
      );
      console.log(resp);
      if (resp) {
        setIsLoading(false);
        setProviderLead(resp);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast.success("Link copied to clipboard");
  };

  const handleSubmit = async () => {
    try {
      setloading(true);
      let input = {
        data: {
          comment: comment,
          provider_user: providerLead.user.id,
          provider_lead: getValue(params, `id`, ""),
        },
      };
      let resp = await createProviderLeadComments(input);
      if (resp) {
        setloading(false);
        getProviderComments();
        setComment("");
        toast.success("Updated successfully");
      } else {
        setloading(false);
        getData();
      }
    } catch (error) {
      setloading(false);
    }
  };

  const handleSubmitLead = async () => {
    try {
      setloading1(true);
      let resp = await updateProviderLead(getValue(params, `id`, ""), {
        data: {
          status: getValue(providerLead, `status`, ""),
          amount: parseFloat(getValue(providerLead, `amount`, "")),
        },
      });
      if (resp) {
        setloading1(false);
        getData();
        toast.success("Updated successfully");
      } else {
        setloading1(false);
        getData();
      }
    } catch (error) {
      setloading1(false);
    }
  };

  const handleChangeSelect = (e) => {
    setValue(providerLead, `status`, e.value);
    setProviderLead({ ...providerLead });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue(providerLead, `[${name}]`, value);
    setProviderLead({ ...providerLead });
  };

  const handleNavigateBack = () => {
    let url = window.location.pathname.split("/")[1];
    switch (url) {
      case "fresh-leads":
        return "fresh-leads";

      case "followup-overdue":
        return "followup-overdue";

      case "interested":
        return "interested";

      case "not-interested":
        return "not-interested";

      default:
        return "not-interested";
    }
  };
  return (
    <DetailLayout>
      {isLoading ? (
        "Please wait..."
      ) : (
        <>
          <NavLink
            to={`/${handleNavigateBack()}${
              typeof window !== "undefined" && window.location.search
            }`}
          >
            <img
              src="/images/generic-images/back-arrow.svg"
              className="img-fluid"
            />
          </NavLink>
          <div className="fresh-leads-details-wrapper">
            <Col md="4">
              <InputField
                inputType="INPUT_REACT_SELECT"
                options={statusList}
                value={statusList.filter(
                  (item) => item.value === getValue(providerLead, `status`, "")
                )}
                onChange={handleChangeSelect}
              />
            </Col>

            <div className="fresh-leads-details-wrapper__introduction">
              <div className="d-flex align-items-center mb-3">
                <img
                  src="/images/dashboard/fresh-leads/leads-inner-page.svg"
                  className="img-fluid mr-3"
                />
                <div className="dashboard-main-wrapper-card__details">
                  <div className="dashboard-main-wrapper-card__list">
                    <ul>
                      <li>{getValue(providerLead, `user.full_name`, "")}</li>
                      <li className="dot"></li>
                      <li>{getValue(providerLead, `user.phone`, "")}</li>
                      <li className="dot"></li>
                      <li>{getValue(providerLead, `user.email`, "")}</li>
                    </ul>
                  </div>
                  <div className="dashboard-main-wrapper-card__list dashboard-main-wrapper-card__list--font">
                    <ul>
                      <li>
                        <Moment format="DD-MMM-YYYY">
                          {getValue(providerLead, `createdAt`, "")}
                        </Moment>
                      </li>
                      <li className="dot"></li>
                      <li>
                        {getValue(
                          providerLead,
                          `provider.sub_category.category.name`,
                          ""
                        )}
                      </li>
                      <li className="dot"></li>
                      <li>
                        {getValue(
                          providerLead,
                          `provider.sub_category.name`,
                          ""
                        )}
                      </li>
                      <li className="dot"></li>
                      <li>{getValue(providerLead, `provider.name`, "")}</li>
                      {getValue(providerLead, `city`, "") ? (
                        <>
                          <li className="dot"></li>
                          <li>{getValue(providerLead, `city`, "")}</li>
                        </>
                      ) : (
                        ""
                      )}
                      {getValue(providerLead, `zip_code`, "") ? (
                        <>
                          <li className="dot"></li>
                          <li>{getValue(providerLead, `zip_code`, "")}</li>
                        </>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  copyToClipboard(
                    getValue(providerLead, `provider.site_url`, "")
                  );
                }}
                className="fresh-leads-details-wrapper__link-referal d-flex align-items-center justify-content-between"
              >
                <a className="fresh-leads-details-wrapper__link">
                  {concatString(
                    getValue(providerLead, `provider.site_url`, ""),
                    80
                  )}
                </a>
                <img
                  src="/images/generic-images/content-copy.svg"
                  className="img-fluid cursor-pointer"
                />
              </div>
              <div className="fresh-leads-details-wrapper__about">
                <div className="form-group">
                  <label
                    htmlFor="amount"
                    className="fresh-leads-details-wrapper__labelstyle"
                  >
                    Amount
                  </label>
                  <InputField
                    inputType="TEXT"
                    id="amount"
                    name="amount"
                    value={getValue(providerLead, `amount`, "")}
                    placeholder="Enter Amount"
                    className="fresh-leads-details-wrapper__input form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="fresh-leads-details-wrapper__cta-button text-right">
              <button
                onClick={handleSubmitLead}
                className="fresh-leads-details-wrapper__cta-button fresh-leads-details-wrapper__cta-button--theme-secondary-btn"
              >
                {loading1 ? "Please wait..." : "save"}
              </button>
            </div>
            <div className="fresh-leads-details-wrapper__provider-comments">
              <h4 className="fresh-leads-details-wrapper__provider-comments-title">
                Provider Comments
              </h4>
              <textarea
                placeholder="Enter Comments"
                rows="5"
                className="w-100 fresh-leads-details-wrapper__custom-text-area"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                name="comment"
              ></textarea>
              <div className="fresh-leads-details-wrapper__cta-button text-right">
                <button
                  onClick={handleSubmit}
                  className="fresh-leads-details-wrapper__cta-button fresh-leads-details-wrapper__cta-button--theme-secondary-btn"
                >
                  {loading ? "Please wait..." : "save"}
                </button>
              </div>
            </div>

            {getValue(providerLeadComments, "length", 0) > 0 ? (
              providerLeadComments.map((item) => {
                return (
                  <div className="fresh-leads-details-wrapper__review d-flex align-items-center">
                    <div className="profile_circle">
                      <h6 className="title">
                        {getFirstLetter(
                          getValue(item, "provider_user.name", "")
                        )}
                      </h6>
                    </div>
                    <div>
                      <h4 className="fresh-leads-details-wrapper__review-heading">
                        {getValue(item, "provider_user.name", "")}
                      </h4>
                      <p className="fresh-leads-details-wrapper__review-date-time">
                        <Moment format="DD MMM YYYY hh:mm a">
                          {getValue(item, "createdAt")}
                        </Moment>
                      </p>
                      <p className="fresh-leads-details-wrapper__review-description">
                        {getValue(item, "comment")}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-center mt-3">No Comments Found</p>
            )}
          </div>
        </>
      )}
    </DetailLayout>
  );
}
