import "@assets/scss/components/loader/shimmer-loader.scss";

export default function LoadingCard() {
  return (
    <div className="px-3">
      <div className="dashboard-main-wrapper-card d-flex align-items-center">
        <div className="dashboard-main-wrapper-card__image-loading animate-loader"></div>
        <div className="dashboard-main-wrapper-card__details">
          <div className="dashboard-main-wrapper-card__list">
            <ul className="mb-2">
              <li className="dashboard-main-wrapper-card__badge animate-loader"></li>
              {/* <li className="dot" /> */}
              <li className="dashboard-main-wrapper-card__badge  animate-loader"></li>
            </ul>
          </div>
          <div className="dashboard-main-wrapper-card__list dashboard-main-wrapper-card__list--font">
            <ul>
              <li className="dashboard-main-wrapper-card__badge animate-loader"></li>
              {/* <li className="dot" /> */}
              <li className="dashboard-main-wrapper-card__badge animate-loader"></li>
              {/* <li className="dot" /> */}
              <li className="dashboard-main-wrapper-card__badge animate-loader"></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
