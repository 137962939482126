import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { PublicRoutes } from "routes/public.routes";
import { PrivateRoutes } from "routes/private.routes";
/* -------------------------------------------------------------------------- */
/*                              UseEffect Section                             */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                               API Section                                  */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                Helper section                              */
/* -------------------------------------------------------------------------- */

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {PublicRoutes.map((item, key) => {
            // eslint-disable-next-line
            const Element = item.component;
            return (
              <Route
                key={key}
                path={item.path}
                element={
                  !localStorage.getItem("jwt") ? (
                    <Element />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
            );
          })}
          {PrivateRoutes.map((item, key) => {
            // eslint-disable-next-line
            const Element = item.component;
            return (
              <Route
                key={key}
                path={item.path}
                element={
                  localStorage.getItem("jwt") ? (
                    <Element />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
            );
          })}
          {/* <Route path={"*"} element={<NotFoundPage />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
