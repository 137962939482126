import LoginLayout from "@components/layout/login-layout.js";
import Login from "@components/Login/login-form.js";
import "@scss/pages/home-page/index.scss";
import { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
// import fetchLoginProviderUser from '../services/loginProviderUser/fetchLoginProviderUser';
import { getValue } from "@utils/lodash";
import { loginUser } from "@services/APIs/auth.service";
import { toast } from "react-toastify";

export default function LoginPage(props) {
  const [request, setRequest] = useState({
    email: "sahil@yopmail.com",
    pwd: "Admin@123",
  });
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setIsLoading(true);
        let resp = await loginUser(request);
        if (getValue(resp, `jwt`, "")) {
          setIsLoading(false);
          localStorage.setItem("jwt", getValue(resp, "jwt", ""));
          localStorage.setItem(
            "user",
            JSON.stringify(getValue(resp, "user", ""))
          );
          window.location.href = "/";
        } else {
          toast.error(getValue(resp, `error.message`, ""));
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };
  return (
    <LoginLayout>
      <Login
        request={request}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        simpleValidator={simpleValidator}
      />
    </LoginLayout>
  );
}
