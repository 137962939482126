import React from 'react';
import '@scss/pages/home-page/index.scss';

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <section className="home-page-wrapper">
        <div className="custom-container">
          <div className="d-flex justify-content-between flex-direction-column-mobile w-100">
            {children}
            <img
              width="594"
              src="/images/home-page/login-image.png"
              className="home-page-wrapper__image img-fluid mx-3"
              alt=""
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default Layout;
