import { useState } from 'react';
import DatePicker from 'react-datepicker';

export default function Dashboardcard(props) {
  const {
    startDate,
    endDate,
    setDateRange,
    handleClearDate,
    handleApplyDateFilter,
  } = props;
  return (
    <div className="fresh-leads-wrapper d-flex justify-content-between align-items-center">
      {/* <h3 className="dashboard-main-wrapper-header__title">Fresh Leads</h3> */}
      {/* <select
        type="select"
        className="dashboard-main-wrapper-header__select-date"
      >
        <option>Jun 11, 2021 - Jun 17, 2021</option>
      </select> */}
      <div className="date-picker-wrapper">
        <ul className="d-flex align-items-center">
          <li>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              onChange={(update) => {
                setDateRange(update);
              }}
              placeholderText="Start Date - End Date"
              className="form-control date-picker"
            />
          </li>
          {/* <li>-</li>
          <li>
            <DatePicker
              selectsRange={true}
              // startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              onChange={(update) => {
                setDateRange(update);
              }}
              placeholderText="End Date"
              className="form-control date-picker"
            />
          </li> */}
        </ul>
      </div>

      <button
        className="theme-button theme-primary-btn mx-3 cursor-pointer"
        disabled={!startDate && !endDate}
        onClick={handleApplyDateFilter}
      >
        Apply
      </button>

      <button
        className="theme-button theme-secondary-btn cursor-pointer"
        disabled={!startDate && !endDate}
        onClick={handleClearDate}
      >
        Clear
      </button>
    </div>
  );
}
