import React from 'react';
import '@scss/components/dashboad-layout.scss';
import DashboardHeader from '@components/dashboard/dashboard-layout/header.js';
import DashboardFooter from '@components/dashboard/dashboard-layout/footer.js';
import DashboardNavigation from '@components/dashboard/dashboard-layout/dashboard-navigation.js';
const DetailLayout = ({ children }) => {
  return (
    <React.Fragment>
      <DashboardHeader />
      <div className="d-flex dashboard-wrapper">
        {/* <DashboardNavigation /> */}
        <div className="dashboard-main-wrapper">{children}</div>
      </div>
      <DashboardFooter />
    </React.Fragment>
  );
};
export default DetailLayout;
