import { getValue } from "@utils/lodash";
import React from "react";

function OfferSvgComponent(props) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
        viewBox="0 0 24 24"
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#423f3f"
        }
      >
        <defs>{/* <style>.a{fill:none;}.b{fill:#5f6368;}</style> */}</defs>
        <path class="a" d="M0,0H24V24H0Z" fill={"none"} />
        <path
          class="b"
          d="M21.41,11.58l-9-9A1.987,1.987,0,0,0,11,2H4A2.006,2.006,0,0,0,2,4v7a2,2,0,0,0,.59,1.42l9,9A1.987,1.987,0,0,0,13,22a1.955,1.955,0,0,0,1.41-.59l7-7A1.955,1.955,0,0,0,22,13,2.02,2.02,0,0,0,21.41,11.58ZM13,20.01,4,11V4h7V3.99l9,9Z"
        />
        <circle
          class="b"
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(5 5)"
        />
      </svg>
    </div>
  );
}

export default OfferSvgComponent;
