import React, { useState, useEffect } from "react";
import Card from "@components/dashboard/common-dashboard-card.js";
import DashboardLayout from "@components/layout/dashboard-layout.js";
// import fetchProviderLeads from "../services/providerLeads/fetchProviderLeads";
import { getValue } from "@utils/lodash";
import Pagination from "react-js-pagination";
// import fetchProviderLeadsCount from "../services/providerLeadsCount/fetchProviderLeadsCount";
import LoadingCard from "@components/loading/loading-card";
import moment from "moment";
import qs from "query-string";
import Header from "@components/dashboard/fresh-leads/header.js";
import NotFound from "@components/not-found/index.js";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getAllProviderLeads } from "@services/APIs/common.service";
import { convertDateTime } from "@common/formatString";
import { useNavigate } from "react-router-dom";

export default function FreshLeads(props) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  // ************************************************************** //
  // *******************    Use Effect Section   ****************** //
  // ************************************************************** //

  useEffect(() => {
    getData(limit, start);

    const urlSearchParams = new URLSearchParams(
      typeof window !== "undefined" && window.location.search
    );
    const params = Object.fromEntries(urlSearchParams.entries());

    let page = window.location.search.split("?page=")[1];
    let activePage = page ? page.split("0") : 1;
    setStart(parseFloat(page ? page : 1));
    setActivePage(
      activePage
        ? parseFloat(page) > 9
          ? parseFloat(activePage) + 1
          : parseFloat(activePage)
        : 1
    );
    if (params.createdAt_gte && params.createdAt_lte) {
      setDateRange([
        new Date(params.createdAt_gte),
        new Date(params.createdAt_lte),
      ]);
    }
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(
      typeof window !== "undefined" && window.location.search
    );
    const params = Object.fromEntries(urlSearchParams.entries());
    let page = window.location.search.split("?page=")[1];
    let activePage = page ? page.split("0") : 1;
    setStart(parseFloat(page ? page : 1));
    setActivePage(
      activePage
        ? parseFloat(page) > 9
          ? parseFloat(activePage) + 1
          : parseFloat(activePage)
        : 1
    );
    if (params.createdAt_gte && params.createdAt_lte) {
      setDateRange([
        new Date(params.createdAt_gte),
        new Date(params.createdAt_lte),
      ]);
    }
    getData(limit, start);
  }, [typeof window !== "undefined" && window.location.href]);

  // ************************************************************** //
  // *******************    Use State Section   ****************** //
  // ************************************************************** //

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [freshLeads, setFreshLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // pagination
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [start, setStart] = useState(
    window.location.search.split("?page=")[1]
      ? window.location.search.split("?page=")[1]
      : 1
  );
  const [limit] = useState(10);

  const handleClearDate = () => {
    setDateRange([null, null]);
    const urlSearchParams = new URLSearchParams(
      typeof window !== "undefined" && window.location.search
    );
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      page: params.page,
    };
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    let queryRequest = qs.stringify(payload);
    props.history.push(`/interested?${queryRequest}`);
  };

  const handleApplyDateFilter = () => {
    let createdAt_gte = moment(startDate).format("YYYY-MM-DD");
    let createdAt_lte = moment(endDate).format("YYYY-MM-DD");

    let payload = {
      page: "1",
      createdAt_gte: createdAt_gte,
      createdAt_lte: createdAt_lte,
    };
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    let queryRequest = qs.stringify(payload);
    props.history.push(`/interested?${queryRequest}`);
  };

  // ************************************************************** //
  // **********************   API Section     ********************** //
  // ************************************************************** //

  const getData = async (limit, start) => {
    let starts = parseFloat(start) - 1;
    try {
      let where = {
        "pagination[limit]": limit,
        "pagination[start]": starts,
        "filters[status]": "interested",
        "filters[$and][1][createdAt][$gte]": getValue(
          UrlParams,
          `createdAt_gte`,
          ""
        )
          ? convertDateTime(getValue(UrlParams, `createdAt_gte`, ""))
          : "",
        "filters[$and][0][createdAt][$lte]": getValue(
          UrlParams,
          `createdAt_lte`,
          ""
        )
          ? convertDateTime(getValue(UrlParams, `createdAt_lte`, ""))
          : "",
        sort: "createdAt:DESC",
        populate:
          "user,provider.logo,provider.sub_category,provider.sub_category.app_image,provider.sub_category.category,provider.sub_category.web_image",
        fields: "id,city,createdAt,zip_code",
      };
      setIsLoading(true);
      let queryRequest = QueryRequestHelper(where);
      let resp = await getAllProviderLeads(queryRequest);
      if (resp) {
        setIsLoading(false);
        setFreshLeads(getValue(resp, "results", []));
        setTotalCount(getValue(resp, `pagination.total`, 0));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // ************************************************************** //
  // *****************   Pagination Section      ****************** //
  // ************************************************************** //

  const navigate = useNavigate();
  const handlePageChange = (start) => {
    setActivePage(start);
    if (parseFloat(start) === 1) {
      setStart(start);
      let payload = {
        page: start,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData(limit, start);
    } else {
      let currentPage =
        (parseFloat(start) - 1) * 10 + (parseFloat(start) - parseFloat(start));
      setStart(currentPage);
      let payload = {
        page: currentPage,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData(limit, currentPage);
    }
  };
  return (
    <DashboardLayout>
      <div className="dashboard-main-wrapper-header d-flex justify-content-between align-items-center px-3">
        <h3 className="dashboard-main-wrapper-header__title">Interested</h3>
        <Header
          dateRange={dateRange}
          setDateRange={setDateRange}
          startDate={startDate}
          endDate={endDate}
          handleClearDate={handleClearDate}
          handleApplyDateFilter={handleApplyDateFilter}
        />
      </div>
      {isLoading ? (
        [1, 2, 3, 4].map((index) => {
          return <LoadingCard key={`fresh-leads-${index}`} />;
        })
      ) : freshLeads.length > 0 ? (
        freshLeads.map((freshLeadsObj, index) => {
          return (
            <div className="px-3">
              <Card
                key={`leads${index}`}
                cardIcon="/images/dashboard/interested/interested-icon.svg"
                commonCard={freshLeadsObj}
                inside={`/interested`}
              />
            </div>
          );
        })
      ) : (
        <NotFound />
      )}
      {totalCount > 10 ? (
        <div className="pagination_container">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={totalCount}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
          />
        </div>
      ) : (
        ""
      )}
    </DashboardLayout>
  );
}
