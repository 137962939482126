import { getValue } from "@utils/lodash";
import React from "react";

function DashboardSvgComponent(props) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
        viewBox="0 0 24 24"
        fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#423f3f"
          }
      >
        {/* <defs><style>.a{fill:none;}.b{fill:#1539b0;}</style></defs> */}
        <path class="a" d="M0,0H24V24H0Z" fill="none" />
        <path
          class="b"
          d="M19,5V7H15V5h4M9,5v6H5V5H9m10,8v6H15V13h4M9,17v2H5V17H9M21,3H13V9h8ZM11,3H3V13h8Zm10,8H13V21h8ZM11,15H3v6h8Z"
          
        />
      </svg>
    </div>
  );
}

export default DashboardSvgComponent;
