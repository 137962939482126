import { getValue } from "@utils/lodash";
import React from "react";

function PendingSvgComponent(props) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
        viewBox="0 0 24 24"
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#423f3f"
        }
      >
        {/* <defs>
          <style>.a{fill:none;}.b{fill:#5f6368;}</style>
        </defs> */}
        <rect class="a" width="24" height="24" fill={"none"} />
        <path
          class="b"
          d="M17,12a5,5,0,1,0,5,5A5,5,0,0,0,17,12Zm1.65,7.35L16.5,17.2V14h1v2.79l1.85,1.85ZM18,3H14.82A2.988,2.988,0,0,0,9.18,3H6A2.006,2.006,0,0,0,4,5V20a2.006,2.006,0,0,0,2,2h6.11a6.743,6.743,0,0,1-1.42-2H6V5H8V8h8V5h2v5.08a7.03,7.03,0,0,1,2,.6V5A2.006,2.006,0,0,0,18,3ZM12,5a1,1,0,1,1,1-1A1,1,0,0,1,12,5Z"
        />
      </svg>
    </div>
  );
}

export default PendingSvgComponent;
